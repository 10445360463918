import React from "react";
import Box from "@material-ui/core/Box";
import {
    Card,
    CardContent,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AccountForm from "./AccountForm";
import ChangePassword from "./ChangePassword";

class Account extends React.Component {

    render() {
        return (
            <Container maxWidth="md">
                <Box my={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" component="h1" className="card-title" color="textSecondary"
                                        gutterBottom>
                                Mina sidor
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>

                <AccountForm user={this.props.user} fetchUser={this.props.fetchUser}/>
                <ChangePassword user={this.props.user}/>

            </Container>
        );
    }
}

export default Account;
