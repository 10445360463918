import React from "react";
import Box from "@material-ui/core/Box";
import {
    Card,
    CardContent,
    Paper,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {getErrorReports} from "./network";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";

class OpenErrorReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorReports: null,
            loading: false,
        };
        this.fetchingErrorReports = false;
        this.getErrorReports = this.getErrorReports.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            this.getErrorReports();
        }
    }

    componentDidMount() {
        this.getErrorReports();
    }

    async getErrorReports() {
        if (this.fetchingErrorReports) {
            return;
        }
        this.fetchingErrorReports = true;
        this.setState({
            errorReports: null,
            loading: true,
        });
        const errorReports = await getErrorReports();
        if (errorReports.status === 'ok') {
            this.setState({
                errorReports: errorReports.data,
                loading: false,
            });
        }
        this.fetchingErrorReports = false;
    }

    render() {
        let content = null;
        if (this.state.loading) {
            content = (<div>Laddar sidan...</div>);
        }
        return (
            <Box my={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h2" className="card-title" color="textSecondary"
                                    gutterBottom>
                            Öppna ärenden
                        </Typography>
                        {this.state.errorReports ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Ärende</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Allvarlighetsgrad</TableCell>
                                            <TableCell>Plats</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.errorReports.map((row) => (
                                            <React.Fragment key={row.id}>
                                                <TableRow>
                                                    <TableCell style={{borderBottom: 'unset'}} component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell style={{borderBottom: 'unset'}}>{row.status}</TableCell>
                                                    <TableCell style={{borderBottom: 'unset'}}>{row.severity}</TableCell>
                                                    <TableCell style={{borderBottom: 'unset'}}>{row.place}</TableCell>
                                                </TableRow>
                                                <TableRow style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                    <TableCell colSpan={4}>
                                                        <pre style={{fontSize: '1.1em'}}>{row.description}</pre>
                                                        {row.imageattachments.map((image_url) => (
                                                            <img src={image_url} alt={''}
                                                                 style={{maxHeight: '100px', maxWidth: '100px'}}/>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (content)}
                    </CardContent>
                </Card>
            </Box>
        );
    }
}

export default OpenErrorReports;
