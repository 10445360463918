import React from "react";
import Box from "@material-ui/core/Box";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {changePassword} from "./network";

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {},
            updatePasswordResponse: null,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        this.setState((prevProps) => ({
            input: {
                ...prevProps.input,
                [name]: event.target.value,
            },
            updatePasswordResponse: null,
        }));
    };

    render() {
        return (

                <Box my={4}>
                    <Card>
                        <form onSubmit={async (event) => {
                            event.preventDefault();
                            if (this.state.input.new_password !== this.state.input.new_password2) {
                                this.setState({updatePasswordResponse: 'Lösenorden matchar inte.'});
                                return;
                            }
                            this.setState({updatePasswordResponse: 'Skickar data.'});

                            const response = await changePassword(
                                this.props.user.id, this.state.input.old_password, this.state.input.new_password);
                            if (response.status === 'ok') {
                                this.setState({updatePasswordResponse: 'Lösenordet är uppdaterat.'})
                            } else {
                                this.setState({updatePasswordResponse: Object.values(response.data.response.data).flat().join(', ')})
                            }
                        }}>
                            <CardContent>
                                <Typography variant="h5" component="h2" className="card-title" color="textSecondary"
                                            gutterBottom>
                                    Lösenord
                                </Typography>

                                <TextField
                                    fullWidth
                                    label="Nuvarande lösenord"
                                    onChange={this.handleChange}
                                    type="password"
                                    name="old_password"
                                /><br/>
                                <TextField
                                    fullWidth
                                    label="Nytt lösenord"
                                    onChange={this.handleChange}
                                    type="password"
                                    name="new_password"
                                /><br/>
                                <TextField
                                    fullWidth
                                    label="Nytt lösenord igen"
                                    onChange={this.handleChange}
                                    type="password"
                                    name="new_password2"
                                /><br/>
                            </CardContent>
                            <CardActions>
                                <Button variant="contained" type="submit" color="primary">
                                    Byt lösenord
                                </Button> <p>{this.state.updatePasswordResponse}</p>
                            </CardActions>
                        </form>
                    </Card>
                </Box>
        );
    }
}

export default ChangePassword;