import React from "react";
import Box from "@material-ui/core/Box";
import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {getArticles} from "./network";
import ReactMarkdown from 'react-markdown'
import moment from "moment";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            lastUpdate: new Date(),
        };
        this.fetchArticles = this.fetchArticles.bind(this);
        this.fetchArticles();
    }

    async fetchArticles() {
        const res = await getArticles();
        if (res.status === 'ok') {
            this.setState({
                articles: res.data,
                lastUpdate: new Date(),
            });
        }
    }

    render() {
        return (
            <Container maxWidth="md">
                <Box my={4}>
                    <Card>
                        <CardContent>
                            <Typography className="card-title" variant="h4" component="h1" color="textSecondary">
                                Nyheter och info
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
                {this.state.articles.map((article) => (
                    <Box my={4}>
                        <Card>
                            <CardContent>
                                <Typography className="card-title" variant="h5" component="h2" gutterBottom>
                                    {article.headline}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Publicerad: {moment(article.visible_from).format('YYYY-MM-DD HH:mm')}
                                </Typography>
                                <ReactMarkdown>{article.body}</ReactMarkdown>
                            </CardContent>
                        </Card>
                    </Box>
                ))}
            </Container>
        );
    }
}

export default Home;
