import React from "react";
import Box from "@material-ui/core/Box";
import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const NotFound = () => {
  return (
    <Container maxWidth="md">
            <Box my={4}>
                <Card>
                    <CardContent>
                        <Typography className="card-title" color="textSecondary" gutterBottom>
                            Sidan kunde inte hittas.
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Container>
  );
};

export default NotFound;
