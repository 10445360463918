import React from "react";
import {loginUser, resetUser} from "./network";
import {Button, Card, CardActions, CardContent, FormControl, FormHelperText, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.state = {error: null, resetPassword: false, submitting: false};
    }

    render() {
        return (
            <Card>
                {this.state.resetPassword ? (
                    <form onSubmit={async (event) => {
                        event.preventDefault();
                        this.setState({'error': null, submitting: true});
                        await resetUser({email: this.emailRef.current.value});
                        this.setState({'error': "Ett mail om lösenordsåterställning har skickats", submitting: false});
                    }}>
                        <CardContent>
                            <Typography className="card-title" color="textSecondary" gutterBottom>
                                Återställ lösenord
                            </Typography>

                            <TextField
                                fullWidth
                                label="E-post"
                                inputRef={this.emailRef}
                                type="email"
                                placeholder={'E-post'}
                            />

                        </CardContent>
                        <CardActions>
                            <Button variant="contained" type="submit" color="primary" disabled={this.state.submitting}>
                                Skicka
                            </Button>
                            <span style={{cursor: 'pointer'}} tabIndex={0} onClick={(event) => {
                                event.preventDefault();
                                this.setState({resetPassword: false})
                            }}>Tillbaka till inloggningssidan.</span>
                        </CardActions>
                    </form>
                ) : (
                    <form onSubmit={async (event) => {
                        event.preventDefault();
                        this.setState({'error': null, submitting: true});
                        const res = await loginUser(this.emailRef.current.value, this.passwordRef.current.value);
                        this.setState({submitting: false});
                        if (res.status === 'ok') {
                            this.props.onSuccess();
                        } else {
                            this.setState({'error': 'Fel användarnamn eller lösenord.'})
                        }

                    }}>
                        <CardContent>
                            <Typography className="card-title" color="textSecondary" gutterBottom>
                                Logga in
                            </Typography>

                            <TextField
                                fullWidth
                                label="E-post"
                                inputRef={this.emailRef}
                                type="email"
                                placeholder={'E-post'}
                            />
                            <TextField
                                fullWidth
                                inputRef={this.passwordRef}
                                label="Lösenord"
                                type="password"
                                autoComplete="current-password"
                            />
                            {this.state.error !== null && (
                                <FormControl error>
                                    <br/>
                                    <FormHelperText id="component-error-text">{this.state.error}</FormHelperText>
                                </FormControl>
                            )}
                            <span style={{cursor: 'pointer'}} tabIndex={0} onClick={(event) => {
                                event.preventDefault();
                                this.setState({resetPassword: true})
                            }}>Glömt lösenord?</span>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" type="submit" color="primary" disabled={this.state.submitting}>
                                Logga in
                            </Button>
                        </CardActions>
                    </form>
                )}
            </Card>
        );
    }
}

export default Login;
