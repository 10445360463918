import React from "react";
import Box from "@material-ui/core/Box";
import {
    Button,
    Card,
    CardContent,
    Paper,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {deleteBooking, getCurrentBookings} from "./network";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";

class CurrentBookings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBookings: null,
            loading: false,
        };
        this.fetchingCurrentBookings = false;
        this.getCurrentBookings = this.getCurrentBookings.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            this.getCurrentBookings();
        }
    }

    componentDidMount() {
        this.getCurrentBookings();
    }

    async getCurrentBookings() {
        if (this.fetchingCurrentBookings) {
            return;
        }
        this.fetchingCurrentBookings = true;
        this.setState({
            currentBookings: null,
            loading: true,
        });
        const currentBookings = await getCurrentBookings();
        if (currentBookings.status === 'ok') {
            this.setState({
                currentBookings: currentBookings.data,
                loading: false,
            });
        }
        this.fetchingCurrentBookings = false;
    }

    render() {
        return (
            <Box my={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h2" className="card-title" color="textSecondary"
                                    gutterBottom>
                            Bokningar
                        </Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Objekt</TableCell>
                                            <TableCell>Datum</TableCell>
                                            <TableCell>Intervall</TableCell>
                                            <TableCell>Kod</TableCell>
                                            <TableCell>Verktyg</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.currentBookings ? (
                                        this.state.currentBookings.map((row) => (
                                            <React.Fragment key={row.id}>
                                                <TableRow>
                                                    <TableCell style={{borderBottom: 'unset'}}>
                                                        {row.bookable_name}
                                                    </TableCell>
                                                    <TableCell style={{borderBottom: 'unset'}}>
                                                        {row.date}
                                                    </TableCell>
                                                    <TableCell style={{borderBottom: 'unset'}}>
                                                        {row.slot_start_time} - {row.slot_end_time}
                                                    </TableCell>
                                                    <TableCell style={{borderBottom: 'unset'}}>
                                                        {`*${("" + row.code).padStart(4, '0')}#`}
                                                    </TableCell>
                                                    <TableCell style={{borderBottom: 'unset'}}>
                                                        <Button onClick={async () => {
                                                            const response = await deleteBooking(row.id)
                                                            if (response.status === 'ok') {
                                                                this.props.setMessage('success', "Bokningen avbokades");
                                                            } else {
                                                                this.props.setMessage('error', Object.values(response.data.response.data).flat().join(', '));
                                                            }
                                                            this.props.reloadTimeSlots();
                                                            this.getCurrentBookings();
                                                        }}>Avboka</Button>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))) : (<TableRow>
                                                    <TableCell style={{borderBottom: 'unset'}} colSpan={5}>
                                                        {this.state.loading ? 'Laddar...' : 'Det finns inga bokningar att visa.'}
                                                    </TableCell>
                                                </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    </CardContent>
                </Card>
            </Box>
        );
    }
}

export default CurrentBookings;
