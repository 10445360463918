import React from "react";
import {withRouter} from "react-router-dom";
import {resetUserConfirm} from "./network";
import {Button, Card, CardActions, CardContent, FormControl, FormHelperText, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, submitting: false};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        this.setState((prevProps) => ({
            input: {
                ...prevProps.input,
                [name]: event.target.value,
            },
        }));
    };

    render() {
        return (
            <Card>
                <form onSubmit={async (event) => {
                    event.preventDefault();
                    if (this.state.input.new_password !== this.state.input.new_password2) {
                        this.setState({error: 'Lösenorden matchar inte.'});
                        return;
                    }
                    this.setState({'error': null, submitting: true});
                    const params = new URLSearchParams(this.props.location.search);
                    const res = await resetUserConfirm({
                        'password': this.state.input.new_password,
                        'token': params.get('token')
                    });
                    this.setState({submitting: false});
                    if (res.status !== 'ok') {
                        this.setState({'error': Object.values(res.data.response.data).flat().join(', ')});
                    } else {
                        this.props.history.push('/');
                    }
                }}>
                    <CardContent>
                        <Typography className="card-title" color="textSecondary" gutterBottom>
                            Återställ lösenord
                        </Typography>

                        <TextField
                            fullWidth
                            label="Nytt lösenord"
                            onChange={this.handleChange}
                            type="password"
                            name="new_password"
                        /><br/>
                        <TextField
                            fullWidth
                            label="Nytt lösenord igen"
                            onChange={this.handleChange}
                            type="password"
                            name="new_password2"
                        /><br/> {this.state.error !== null && (
                        <FormControl error>
                            <br/>
                            <FormHelperText id="component-error-text">{this.state.error}</FormHelperText>
                        </FormControl>
                    )}
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" type="submit" color="primary" disabled={this.state.submitting}>
                            Skicka
                        </Button>
                    </CardActions>
                </form>
            </Card>
        );
    }
}

export default withRouter(ResetPassword);
