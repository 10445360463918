import axios from "axios";
import history from "./utils/history";

export const loginUser = async (username, password) => {
    try {
        const response = await axios.post('/api/auth/', {username, password})
        // If request is good...
        // Update state to indicate user is authenticated
        const token = response.data.token;
        localStorage.setItem("token", token);
        history.push("/");
        return {status: 'ok'}

    } catch (error) {
        return {status: 'error', data: error}
    }
};

export const resetUser = async (data) => {
    try {
        await axios.post('/api/password_reset/', data)
        return {status: 'ok'}

    } catch (error) {
        return {status: 'error', data: error}
    }
};


export const resetUserConfirm = async (data) => {
    try {
        const res = await axios.post('/api/password_reset/confirm/', data)
        history.push("/");
        return {status: 'ok', data: res.data};
    } catch (error) {
        return {status: 'error', data: error};
    }
};


export const logoutUser = () => {
    localStorage.removeItem("token");
};

export const getUser = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get('/api/users/', {
            headers: {
                Authorization: 'Token ' + token
            }
        });
        if (response.data.length === 1) {
            return {status: 'ok', data: response.data[0]};
        } else {
            return {status: 'error', data: response}
        }
    } catch (error) {
        return {status: 'error', data: error}
    }
};

export const updateUser = async (id, data) => {
    const fields = ['first_name', 'last_name', 'email', 'phone'];
    const token = localStorage.getItem("token");
    const updateData = {};
    for (const field of fields) {
        if (data[field]) {
            updateData[field] = data[field];
        }
    }
    try {
        const response = await axios.put(`/api/users/${id}/`, updateData, {
            headers: {
                Authorization: 'Token ' + token
            }
        });
        if (response.data) {
            return {status: 'ok', data: response.data};
        } else {
            return {status: 'error', data: response}
        }
    } catch (error) {
        return {status: 'error', data: error}
    }
};

export const changePassword = async (id, old_password, new_password) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.put(`/api/users/${id}/change-password/`, {old_password, new_password}, {
            headers: {
                Authorization: 'Token ' + token
            }
        });
        if (response.data) {
            return {status: 'ok', data: response.data};
        } else {
            return {status: 'error', data: response}
        }
    } catch (error) {
        return {status: 'error', data: error}
    }
};

export const getApartment = async (apartmentId) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/api/apartments/${apartmentId}/`, {
            headers: {Authorization: 'Token ' + token}
        });
        return {status: 'ok', data: response.data};
    } catch (error) {
        return {status: 'error', data: error}
    }
};

export const getEstate = async (estateId) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/api/estates/${estateId}/`, {
            headers: {Authorization: 'Token ' + token}
        });
        return {status: 'ok', data: response.data};
    } catch (error) {
        return {status: 'error', data: error}
    }
};


export const getErrorReports = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/api/error-reports/`, {
            headers: {Authorization: 'Token ' + token}
        });
        return {status: 'ok', data: response.data};
    } catch (error) {
        return {status: 'error', data: error}
    }
};


export const postErrorReport = async (data) => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    const fields = ['place', 'severity', 'description'];
    for (const field of fields) {
        if (data[field]) {
            formData.append(field, data[field]);
        }
    }
    for (var i = 0; i < (data['images'] || []).length; i++) {
        let file = data['images'].item(i);
        formData.append('images', file, file.name);
    }


    try {
        const response = await axios.post(`/api/error-reports/`, formData, {
            headers: {
                'Authorization': 'Token ' + token,
                'Content-Type': 'multipart/form-data',
            }
        });
        return {status: 'ok', data: response.data};
    } catch (error) {
        return {status: 'error', data: error}
    }
};


export const getBookables = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/api/bookables/`, {
            headers: {Authorization: 'Token ' + token}
        });
        return {status: 'ok', data: response.data};
    } catch (error) {
        return {status: 'error', data: error}
    }
};

export const getBookableSlots = async (bookableId, year, week) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/api/bookables/${bookableId}/slots/?year=${year}&week=${week}`, {
            headers: {Authorization: 'Token ' + token}
        });
        return {status: 'ok', data: response.data};
    } catch (error) {
        return {status: 'error', data: error}
    }
};


export const getCurrentBookings = async () => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/api/bookings/user/`, {
            headers: {Authorization: 'Token ' + token}
        });
        return {status: 'ok', data: response.data};
    } catch (error) {
        return {status: 'error', data: error}
    }
};


export const createBooking = async (bookableId, data) => {
    const token = localStorage.getItem("token");
    const formData = {};
    const fields = ['slotId', 'date'];
    for (const field of fields) {
        if (data[field]) {
            formData[field] = data[field];
        }
    }

    try {
        const response = await axios.post(`/api/bookables/${bookableId}/bookings/`, formData, {
            headers: {
                'Authorization': 'Token ' + token,
                'Content-Type': 'application/json',
            }
        });
        return {status: 'ok', data: response.data};
    } catch (error) {
        return {status: 'error', data: error}
    }
};


export const deleteBooking = async (bookingId) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.delete(`/api/bookings/${bookingId}/`, {
            headers: {
                'Authorization': 'Token ' + token,
            }
        });
        return {status: 'ok', data: response.data};
    } catch (error) {
        return {status: 'error', data: error}
    }
};


export const getArticles = async (estateId) => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/api/news/`, {
            headers: {Authorization: 'Token ' + token}
        });
        return {status: 'ok', data: response.data};
    } catch (error) {
        return {status: 'error', data: error}
    }
};


