import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExternalLink from '@material-ui/core/Link';

import history from "./utils/history";
import './App.scss';
import Home from "./Home";
import ErrorReport from "./ErrorReport";
import Booking from "./Booking";
import Login from "./Login";
import {getApartment, getEstate, getUser, logoutUser} from "./network";
import {AppBar, Button, Hidden, Toolbar} from "@material-ui/core";
import HomeIcon from '@material-ui/icons/Home';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EventIcon from '@material-ui/icons/Event';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import Logo from "./logo";
import vihemLogo from "./vihem.svg";
import vihemWhiteLogo from "./vihem_white.svg";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Account from "./Account";
import NotFound from "./NotFound";
import ResetPassword from "./ResetPassword";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#306b6a',
        },
    },
    overrides: {
        // Style sheet name ⚛️
        MuiButton: {
            // Name of the rule
            text: {
                minWidth: 52,
            },
        },
    },
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: false,
        };
        this.fetchingUser = false;
        this.getUser = this.getUser.bind(this);
    }

    async getUser() {
        if (this.fetchingUser) {
            return;
        }
        this.fetchingUser = true;
        this.setState({
            user: null,
            loading: true,
        });
        const user = await getUser();
        if (user.status === 'ok') {
            const apartments = [];
            const apartments_hr = [];
            for (const apartment_id of user.data.apartment_ids) {
                let apartment = await getApartment(apartment_id);
                if (apartment.status === 'ok') {
                    let estate = await getEstate(apartment.data.estate_id);
                    if (estate.status === 'ok') {
                        apartments_hr.push(`${estate.data.name} Lgh. ${apartment.data.apartment_number}`)
                        apartments.push({...apartment.data, estate: estate.data})
                    }
                }
            }
            const userData = {...user.data, apartments, apartments_hr}
            this.setState({
                user: userData,
                loading: false,
            });
        } else {
            logoutUser();
            this.setState({
                user: null,
                loading: false,
            });
        }
        this.fetchingUser = false;
    }

    render() {
        let content = null;
        if (this.state.loading) {
            content = (<div>Laddar sidan...</div>);
        } else {
            const token = localStorage.getItem("token");
            if (token && this.state.user === null) {
                this.getUser();
                content = (<div>Laddar sidan...</div>);
            } else if (!token && this.state.user === null) {
                content = (<Login onSuccess={() => {
                    this.getUser();
                }}/>)
            }
        }

        return (
            <Router history={history}>
                <ThemeProvider theme={theme}>
                    {content ? (
                        <Container maxWidth="sm">
                            <Box my={4}>
                                <Button href="https://vi-hem.se/">
                                    <Typography variant="h4" component="h1" gutterBottom>
                                        <img src={vihemLogo} alt="Logga" style={{width: '100%'}}/>
                                    </Typography>
                                </Button>
                                <Switch>
                                    <Route exact path="/reset-password">
                                        <ResetPassword />
                                    </Route>
                                    <Route>
                                        {content}
                                    </Route>
                                </Switch>
                            </Box>
                        </Container>
                    ) : (
                        <>
                            <AppBar position="static">
                                <Toolbar variant="dense">
                                    <Button color="inherit" href="https://vi-hem.se/" title={'https://vi-hem.se/'}>
                                        <Hidden mdUp>
                                            <Logo/>
                                        </Hidden>
                                        <Hidden smDown>
                                            <Typography variant="h6" color="inherit">
                                                <img src={vihemWhiteLogo} alt="Logga" style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '2.5rem',
                                                }}/>
                                            </Typography>
                                        </Hidden>
                                    </Button>
                                    <Button component={Link} to={'/'} color="inherit" title={"Hem"}>
                                        <HomeIcon/><Hidden smDown>&nbsp;Hem</Hidden>
                                    </Button>
                                    {this.state.user.apartments.length !== 0 && (<>
                                        <Button component={Link} to={'/error-report'} color="inherit"
                                                title={"Felanmälan"}>
                                            <ReportProblemIcon/><Hidden smDown>&nbsp;Felanmälan</Hidden>
                                        </Button>
                                        <Button component={Link} to={'/booking'} color="inherit"
                                                title={'Boka tvättstuga'}>
                                            <EventIcon/><Hidden smDown>&nbsp;Boka tvättstuga</Hidden>
                                        </Button>
                                    </>)}
                                    <Button component={Link} to={'/account'} color="inherit" title={'Mina sidor'}>
                                        <PersonIcon/><Hidden smDown>&nbsp;Mina sidor</Hidden>
                                    </Button>
                                    <Button color="inherit" className="logout-button" onClick={() => {
                                        this.setState({user: null});
                                        logoutUser()
                                    }} title={'Logga ut'}>
                                        <ExitToAppIcon/><Hidden smDown>&nbsp;Logga ut</Hidden>
                                    </Button>
                                </Toolbar>
                            </AppBar>
                            <div className="page-content">
                                <Switch>
                                    <Route exact path="/">
                                        <Home/>
                                    </Route>
                                    {this.state.user.apartments.length !== 0 && (
                                        <Route path="/error-report">
                                            <ErrorReport user={this.state.user}/>
                                        </Route>
                                    )}
                                    {this.state.user.apartments.length !== 0 && (
                                        <Route path="/booking">
                                            <Booking user={this.state.user}/>
                                        </Route>
                                    )}
                                    <Route path="/account">
                                        <Account user={this.state.user} fetchUser={this.getUser}/>
                                    </Route>
                                    <Route>
                                        <NotFound/>
                                    </Route>
                                </Switch>
                            </div>
                        </>)}
                    <div className="footer">
                        <hr/>
                        <Typography variant="body2" color="textSecondary" align="center">
                            {'Copyright © '}
                            <ExternalLink color="inherit" href="https://github.com/andersonjonathan">
                                Jonathan Anderson
                            </ExternalLink>{' '}
                            {new Date().getFullYear()}
                            {'.'}
                        </Typography>
                    </div>
                </ThemeProvider>
            </Router>
        );
    }
}

export default App;
