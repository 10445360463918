import React from "react";
import {postErrorReport} from "./network";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormHelperText,
    InputLabel, Select, Snackbar,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import OpenErrorReports from "./OpenErrorReports";
import MuiAlert from "@material-ui/lab/Alert";

class ErrorReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            input: {
                severity: 'normal',
                place: 'apartment',
            },
            response: null,
            message: null,
            lastUpdate: new Date(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.form = React.createRef();
    }

    handleChange(event) {
        const name = event.target.name;
        this.setState((prevProps) => ({
            input: {
                ...prevProps.input,
                [name]: event.target.type === 'file' ? event.target.files : event.target.value,
            }
        }));
    }

    async handleUpload() {
        this.setState({response: 'Skickar data.'});
        const response = await postErrorReport(this.state.input);
        if (response.status === 'ok') {
            this.setState({
                error: null,
                input: {
                    severity: 'normal',
                    place: 'apartment',
                    description: '',
                },
                message: {severity: 'success', text: 'Felanmälan är skickad.'},
                lastUpdate: new Date(),
            });
            this.form.current.reset();
        } else {
            this.setState({message: {severity: 'success', text: Object.values(response.data.response.data).flat().join(', ')}})
        }
    }

    render() {
        return (
            <Container maxWidth="md">
                <Box my={4}>
                    <Card>
                        <form onSubmit={async (event) => {
                            event.preventDefault();
                            this.handleUpload();
                            this.setState({'error': null});
                        }} ref={this.form}>
                            <CardContent>
                                <Typography variant="h4" component="h1" className="card-title" color="textSecondary"
                                            gutterBottom>
                                    Registrera felanmälan
                                </Typography>
                                <Typography color="textSecondary">
                                    Din information och kontaktuppgifter.
                                    <br/>
                                    <p>Namn: {this.props.user.first_name} {this.props.user.last_name}</p>
                                    <p>E-post: {this.props.user.email}</p>
                                    <p>Tel: {this.props.user.phone}</p>
                                    <p>Lägenhet: {this.props.user.apartments_hr ? this.props.user.apartments_hr.join(', ') : ''}</p>
                                </Typography>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="severity">Allvarlighetsgrad</InputLabel>
                                    <Select
                                        native
                                        value={this.state.input.severity}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            name: 'severity',
                                            id: 'severity',
                                        }}
                                    >
                                        <option value={'low'}>Låg</option>
                                        <option value={'normal'}>Normal</option>
                                        <option value={'high'}>Hög</option>
                                    </Select>
                                </FormControl><br/>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="place">Var gäller felanmälan</InputLabel>
                                    <Select
                                        native
                                        value={this.state.input.place}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            name: 'place',
                                            id: 'place',
                                        }}
                                    >
                                        <option value={'apartment'}>Lägenheten</option>
                                        <option value={'estate'}>Fastigheten</option>
                                        <option value={'outside'}>Utomhus</option>
                                    </Select>
                                </FormControl><br/>
                                <TextField
                                    fullWidth
                                    label="Felbeskrivning"
                                    placeholder="Beskriv felet här..."
                                    value={this.state.input.description}
                                    onChange={this.handleChange}
                                    name="description"
                                    multiline
                                    helperText={"Beskriv felet så utförligt som möjligt."}
                                /><br/>
                                <TextField
                                    fullWidth
                                    label="Bild"
                                    inputProps={{
                                        name: 'images',
                                        id: 'images',
                                        accept: "image/*",
                                        multiple: true
                                    }}
                                    name="images"
                                    type={'file'}
                                    onChange={this.handleChange}
                                /><br/>
                                {this.state.error !== null && (
                                    <FormControl error>
                                        <br/>
                                        <FormHelperText id="component-error-text">{this.state.error}</FormHelperText>
                                    </FormControl>
                                )}
                            </CardContent>
                            <CardActions>
                                <Button variant="contained" type="submit" color="primary">
                                    Skicka
                                </Button>
                            </CardActions>
                        </form>
                    </Card>
                </Box>
                {this.state.message && (
                    <Snackbar
                        open={true}
                        autoHideDuration={6000}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            this.setState({message: null});
                        }}
                    >
                        <MuiAlert elevation={6} variant="filled" onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            this.setState({message: null});
                        }} severity={this.state.message.severity}>
                            {this.state.message.text}
                        </MuiAlert>
                    </Snackbar>
                )}
                <OpenErrorReports user={this.props.user} lastUpdate={this.state.lastUpdate} />
            </Container>
        );
    }
}

export default ErrorReport;
