import React from "react";
import Box from "@material-ui/core/Box";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {updateUser} from "./network";

class AccountForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                first_name: this.props.user.first_name,
                last_name: this.props.user.last_name,
                email: this.props.user.email,
                phone: this.props.user.phone,
            },
            updateUserResponse: null,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        this.setState((prevProps) => ({
            input: {
                ...prevProps.input,
                [name]: event.target.value,
            },
            updateUserResponse: null,
        }));
    };

    render() {
        return (
            <Box my={4}>
                <Card>
                    <form onSubmit={async (event) => {
                        event.preventDefault();

                        this.setState({updateUserResponse: 'Skickar data.'});
                        const data = {
                            first_name: this.state.input.first_name.trim(),
                            last_name: this.state.input.last_name.trim(),
                            email: this.state.input.email.trim(),
                            phone: this.state.input.phone.trim(),
                        };
                        const response = await updateUser(this.props.user.id, data);
                        if (response.status === 'ok') {
                            this.setState({updateUserResponse: 'Användaren är uppdaterad.'});
                            this.props.fetchUser();
                        } else {
                            this.setState({updateUserResponse: 'Ett fel uppstod.'});
                        }
                    }}>
                        <CardContent>
                            <Typography variant="h5" component="h2" className="card-title" color="textSecondary"
                                        gutterBottom>
                                Kontaktuppgifter
                            </Typography>
                            <TextField
                                fullWidth
                                label="Förnamn"
                                defaultValue={this.props.user.first_name}
                                onChange={this.handleChange}
                                name="first_name"
                            /><br/>
                            <TextField
                                fullWidth
                                label="Efternamn"
                                defaultValue={this.props.user.last_name}
                                onChange={this.handleChange}
                                name="last_name"
                            /><br/>
                            <TextField
                                fullWidth
                                label="E-post"
                                defaultValue={this.props.user.email}
                                onChange={this.handleChange}
                                name="email"
                            /><br/>
                            <TextField
                                fullWidth
                                label="Telefon"
                                defaultValue={this.props.user.phone}
                                onChange={this.handleChange}
                                name="phone"
                            /><br/>
                            <p>Lägenhet: {this.props.user.apartments_hr ? this.props.user.apartments_hr.join(', ') : '-'}</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" type="submit" color="primary">
                                Uppdatera
                            </Button><br/>
                            <p>{this.state.updateUserResponse}</p>
                        </CardActions>
                    </form>
                </Card>
            </Box>
        );
    }
}

export default AccountForm;