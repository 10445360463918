import React from "react";
import Box from "@material-ui/core/Box";
import moment from 'moment';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    Grid,
    InputLabel, Paper,
    Select, Snackbar,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import MuiAlert from '@material-ui/lab/Alert';
import {getBookables, getBookableSlots, createBooking} from "./network";
import CurrentBookings from "./CurrentBookings";

class Booking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bookable: null,
            bookables: [],
            slots: [],
            currentWeek: moment().isoWeek(),
            currentYear: moment().year(),
            week: moment().isoWeek(),
            year: moment().year(),
            selectedSlot: null,
            message: null,
            lastUpdate: new Date(),
        };
        this.fetchBookables = this.fetchBookables.bind(this);
        this.fetchBookableSlots = this.fetchBookableSlots.bind(this);
        this.fetchBookables();
    }

    async fetchBookableSlots(bookable, year, week) {
        let bookableId = bookable;
        if (bookable === undefined || bookable === null) {
            bookableId = this.state.bookable
        }
        const res = await getBookableSlots(bookableId, year || this.state.year, week || this.state.week);
        if (res.status === 'ok') {
            this.setState({
                slots: res.data
            });
        }
    }

    async fetchBookables() {
        const res = await getBookables();
        if (res.status === 'ok') {
            const bookable = res.data.length > 0 ? res.data[0].id : null;
            this.setState({
                bookables: res.data,
                bookable,
                slots: []
            });
            if (bookable) {
                this.fetchBookableSlots(bookable);
            }
        }
    }

    render() {
        const dayNames = {
            1: 'Måndag',
            2: 'Tisdag',
            3: 'Onsdag',
            4: 'Torsdag',
            5: 'Fredag',
            6: 'Lördag',
            7: 'Söndag',
        }
        return (
            <Container maxWidth="md">
                <Box my={4}>
                    <Card>
                        <CardContent>
                            <Typography className="card-title" color="textSecondary" gutterBottom>
                                Bokning
                            </Typography>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="bookable" shrink>Välj tvättstuga</InputLabel>
                                    <Select
                                        native
                                        value={this.state.bookable}
                                        onChange={(e) => {
                                            this.setState({
                                                bookable: e.target.value,
                                                selectedSlot: null,
                                                slots: [],
                                            });
                                            this.fetchBookableSlots(e.target.value);
                                        }}
                                        inputProps={{
                                            name: 'bookable',
                                            id: 'bookable',
                                        }}
                                    >
                                        {this.state.bookables.map((bookable) => (
                                            <option value={bookable.id} key={bookable.id}>{bookable.name}</option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} style={{textAlign: "left"}}>
                                            {(this.state.currentWeek < this.state.week || this.state.year !== this.state.currentYear) && (
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => {
                                                        if (1 === this.state.week) {
                                                            const week = moment().year(this.state.year - 1).isoWeeksInYear();
                                                            this.fetchBookableSlots(null, this.state.year - 1, week);
                                                            this.setState({
                                                                year: this.state.year - 1,
                                                                week,
                                                            });
                                                        } else {
                                                            this.fetchBookableSlots(null, this.state.year, this.state.week - 1);
                                                            this.setState({week: this.state.week - 1});
                                                        }
                                                    }}
                                                >Föregående vecka</Button>
                                            )}
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "center"}}>
                                            <span>{`Vecka ${this.state.week}, ${this.state.year}`}</span>

                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "right"}}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    if (moment().year(this.state.year).isoWeeksInYear() === this.state.week) {
                                                        this.fetchBookableSlots(null, this.state.year + 1, 1);
                                                        this.setState({year: this.state.year + 1, week: 1});
                                                    } else {
                                                        this.fetchBookableSlots(null, this.state.year, this.state.week + 1);
                                                        this.setState({week: this.state.week + 1});
                                                    }
                                                }}
                                            >Nästa vecka</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: '1rem'}}>
                                <Grid container justify="center" spacing={2} style={{flexGrow: 1}}>
                                    {[1, 2, 3, 4, 5, 6, 7].map((value) => {
                                        const date = moment().year(this.state.year).isoWeek(this.state.week).isoWeekday(value);
                                        return (
                                            <Grid key={date.format('YYYY-MM-DD')} item xs>
                                                <Paper>
                                                    {dayNames[value]} {date.format('D/M')}
                                                    {this.state.slots.length === 7 && this.state.slots[value - 1].map((slot) => {
                                                        const startTime = moment(slot.start_time, 'HH:mm:ss').add(30, 'seconds').startOf('minute').format('HH:mm');
                                                        const endTime = moment(slot.end_time, 'HH:mm:ss').add(30, 'seconds').startOf('minute').format('HH:mm');
                                                        let selected = this.state.selectedSlot &&
                                                            this.state.selectedSlot.date === date.format('YYYY-MM-DD') &&
                                                            this.state.selectedSlot.slotId === slot.id

                                                        return (
                                                            <Button
                                                                key={`${date.format('YYYY-MM-DD')}-${slot.id}`}
                                                                color="primary"
                                                                variant={selected ? "contained" : "outlined"}
                                                                disabled={slot.booked}
                                                                onClick={() => {
                                                                    if (slot.booked) {
                                                                        return;
                                                                    }
                                                                    if (!selected) {
                                                                        this.setState({
                                                                            selectedSlot: {
                                                                                date: date.format('YYYY-MM-DD'),
                                                                                slotId: slot.id
                                                                            }
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            selectedSlot: null,
                                                                        });
                                                                    }
                                                                }}
                                                                disableElevation
                                                            >
                                                                {`${startTime}-${endTime}`}
                                                            </Button>
                                                        )
                                                    })}
                                                </Paper>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            {this.state.selectedSlot && (
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={this.state.submitting}
                                    onClick={async () => {
                                        if (this.submitting) {
                                            return;
                                        }
                                        this.submitting = true;
                                        this.setState({submitting: true});
                                        const resp = await createBooking(this.state.bookable, this.state.selectedSlot);
                                        this.submitting = false;
                                        this.setState({submitting: false, selectedSlot: null});
                                        if (resp.status === 'ok') {
                                            this.setState({
                                                message: {
                                                    severity: 'success',
                                                    text: 'Bokningen är genomförd',
                                                },
                                                lastUpdate: new Date(),
                                            });
                                        } else {
                                            this.setState({
                                                message: {
                                                    severity: 'error',
                                                    text: Object.values(resp.data.response.data).flat().join(', '),
                                                },
                                                lastUpdate: new Date(),
                                            });
                                        }
                                        this.fetchBookableSlots();
                                    }}
                                >
                                    Boka
                                </Button>
                            )}
                        </CardActions>
                    </Card>
                </Box>
                {this.state.message && (
                    <Snackbar
                        open={true}
                        autoHideDuration={6000}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            this.setState({message: null});
                        }}
                    >
                        <MuiAlert elevation={6} variant="filled" onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            this.setState({message: null});
                        }} severity={this.state.message.severity}>
                            {this.state.message.text}
                        </MuiAlert>
                    </Snackbar>
                )}

                <CurrentBookings
                    user={this.props.user}
                    lastUpdate={this.state.lastUpdate}
                    reloadTimeSlots={this.fetchBookableSlots}
                    setMessage={(severity, message) => {
                        this.setState({
                            message: {
                                severity: severity,
                                text: message,
                            }
                        });
                    }}
                />
            </Container>
        );
    }
}

export default Booking;
